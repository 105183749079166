import React from 'react'
import styled from 'styled-components'

const ShortCuts = () => {
  const handleTwitterClick = () => {
    window.open('https://twitter.com/SeclandGory', '_blank')
  }
  const handleTelegramClick = () => {
    window.open('https://t.me/+pscm0eGlpdQwZDgx', '_blank')
  }
  const handleChartClick = () => {
    window.open('https://pump.fun/profile/Gory', '_blank')
  }
  return (
    <Content>
      <Links
        onClick={() => {
          handleTelegramClick()
        }}
      >
        telegram
      </Links>
      <Links
        onClick={() => {
          handleChartClick()
        }}
      >
        chart
      </Links>
      <Links
        onClick={() => {
          handleTwitterClick()
        }}
      >
        twitter
      </Links>
    </Content>
  )
}

export default ShortCuts

const Content = styled.div`
  display: flex;
  color: white;
  margin-top: 30px;
  justify-content: center;
`
const Links = styled.p`
  font-size: 1.5rem;
  margin: 10px 40px;
  &:hover {
    cursor: pointer;
    color: #ff2585;
  }
`
