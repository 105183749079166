import { Box, Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Image_01 from '../assets/images/25.png'
import Image_02 from '../assets/images/22new.png'
const HowToBuy = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Title>how to buy</Title>
              <SubTitile>CREATE A WALLET</SubTitile>
              <Description>
                Download Phantom or your wallet of choice from the app store or
                Google Play for free. Desktop users, download the Google Chrome
                extension by going to Phantom.
              </Description>
            </Grid>
            <Grid item xs={12} md={6}>
              <BannerImg src={Image_01} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SubTitile> Get Some SOL</SubTitile>
              <Description>
                HHave SOL in your wallet to switch to $GFS. If you don’t have
                any SOL, you can buy directly on Phantom, transfer from another
                wallet, or buy on another exchange and send it to your wallet.
              </Description>
              <SubTitile>Go to Raydium</SubTitile>
              <Description>
                Connect to Raydium. Go to Raydium in google chrome or on the
                browser inside your Phantom app. Connect your wallet. Paste the
                $GFS token address into Raydium, select $GFS, and confirm. When
                Phantom prompts you for a wallet signature, sign.
              </Description>
              <SubTitile>$GFS</SubTitile>
              <Description>
                Switch SOL for $$GFS. We have Zero taxes so you don’t need to
                worry about buying with a specific slippage, although you may
                $GFS has no association with Gary Gensler. This token is simply
                a meme coin with no intrinsic value or expectation of financial
                return.
              </Description>
            </Grid>
            <Grid item xs={12} md={6}>
              <BannerImg src={Image_02} />
            </Grid>
          </Grid>
        </Content>
      </Box>
    </Container>
  )
}

export default HowToBuy

const Container = styled.div``
const Content = styled.div`
  display: flex;
  padding: 2%;
  color: white;
  width: 75%;
  margin: auto;
`
const Title = styled.div`
  font-size: 64px;
  font-weight: 400;
`
const Description = styled.div`
  font-size: 21px;
`
const SubTitile = styled.div`
  font-size: 28px;
  margin: 13px 0;
`
const BannerImg = styled.img`
  width: 70%;
  margin: auto;
  max-height: 400px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`
