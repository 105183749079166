import About from './layouts/About'
import Banner from './layouts/Banner'
import ImageCarousel from './layouts/Carousel'
import Footer from './layouts/Footer'
import HowToBuy from './layouts/HowToBuy'
import NavBar from './layouts/NavBar'
import ShortCuts from './layouts/ShortCuts'
import Token from './layouts/Token'

function App() {
  return (
    <div className='App'>
      <NavBar />
      <Banner />
      <ShortCuts />
      <Token />
      <About />
      <HowToBuy />
      <ImageCarousel />
      <Footer />
    </div>
  )
}

export default App
