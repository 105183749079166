import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import NavImage from '../assets/images/21.png'
import styled from 'styled-components'
const NavBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Content>
            <NavLogo src={NavImage} alt='' srcset='' />
            <NavText> $GFS</NavText>
          </Content>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <CusContent>
            <Links>About</Links>
            <Links>How to buy</Links>
            <Links>Memes</Links>
          </CusContent>
        </Grid>
        <Grid item xs={12} md={2}>
          <Content>
            <Button>BUY $GFS</Button>
          </Content>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NavBar

const Content = styled.div`
  display: flex;
  color: white;
  justify-content: center;
`

const CusContent = styled.div`
  display: flex;
  color: white;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`
const NavLogo = styled.img`
  width: 60px;
  height: 40px;
  margin: 10px 10px 0 0;
  @media only screen and (max-width: 600px) {
    width: 70px;
    margin: 2px;
  }
`

const NavText = styled.div`
  font-size: 2.25rem;
`
const Links = styled.p`
  font-size: 1.5rem;
  margin: 10px;
`
const Button = styled.span`
  background-color: #ff0000;
  border-width: 0 0 0 0;
  border-color: rgb(229, 231, 235);
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  color: white;
`

// 40min
