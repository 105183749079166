import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Container>
      © 2024 by $GFS. <br /> <br /> <br /> All rights reserved.
    </Container>
  )
}

export default Footer

const Container = styled.div`
  margin-top: 30px;
  text-align: center;
  background-color: black;
  font-size: 21px;
  color: white;
  padding: 2%;
`
