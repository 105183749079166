import React from 'react'
import styled from 'styled-components'
import BannerImage from '../assets/images/26.png'
const Banner = () => {
  return (
    <Content>
      <BannerImg src={BannerImage} alt='' srcset='' />
    </Content>
  )
}

export default Banner

const Content = styled.div`
  display: flex;
  color: white;
  margin-top: 30px;
`

const BannerImg = styled.img`
  width: 65vw;
  margin: auto;
  max-height: 600px;
  /* margin: 10px 10px 0 0; */
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`
