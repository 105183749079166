import React from 'react'
import styled from 'styled-components'
import Carousel from 'react-multi-carousel'

import Image_01 from '../assets/images/22new.png'
import Image_02 from '../assets/images/21.png'
import Image_03 from '../assets/images/25.png'
import Image_04 from '../assets/images/vbv.png'

import 'react-multi-carousel/lib/styles.css'
const image = [Image_01, Image_02, Image_03, Image_04]
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1781 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1781, min: 1364 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1364, min: 926 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 926, min: 0 },
    items: 1,
  },
}
const ImageCarousel = () => {
  return (
    <Container>
      <Content>
        <Title>memes</Title>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          // ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition='all .5'
          transitionDuration={500}
          containerClass='carousel-container'
          removeArrowOnDeviceType={['tablet', 'mobile']}
          // deviceType={this.props.deviceType}
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
        >
          {image.map((img) => {
            return <BannerImg src={img} />
          })}
        </Carousel>
      </Content>
    </Container>
  )
}

export default ImageCarousel
const Container = styled.div`
  background: white !important;
  padding: 3%;
`
const Content = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
  color: #0149ab;
  background-color: white;
`
const Title = styled.div`
  font-size: 115px;
  font-weight: 400;
  @media only screen and (max-width: 600px) {
    font-size: 100px;
  }
`
const BannerImg = styled.img`
  width: 300px;
  margin: auto;
  height: 300px;
  @media only screen and (max-width: 600px) {
    width: 300px;
    margin: auto;
  }
`
