import React from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Image from '../assets/images/vbv.png'

const About = () => {
  return (
    <Container>
      <Content>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BannerImg src={Image} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Title>about</Title>
              <Description>
                Intraducin Gory from Secland ($GFS) – Da Meme Coin wit a fun and
                crazy vibe! <br />
                Wit da power of Secland Gory leeds da charge to brek doun
                barriers and spreed joy akross da blockchain. <br /> He is no
                another ruler of da Secland but a legendari celebrity among
                millions of crypto fans in da most phenomenal way possibul. He a
                serius challnger in da crypto space. $GFS incorporates
                innovative features and a deflationary mechanism to ensur
                long-term sustainability.
              </Description>
            </Grid>
          </Grid>
        </Box>
      </Content>
    </Container>
  )
}

export default About

const Container = styled.div`
  background: white !important;
  padding: 3%;
`
const Content = styled.div`
  display: flex;
  background: white !important;
  width: 75%;
  margin: auto;
`
const BannerImg = styled.img`
  width: 80%;
  margin: auto;
  max-height: 600px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`
const Title = styled.div`
  font-size: 115px;
`
const Description = styled.div`
  font-size: 21px;
  text-align: center;
`
